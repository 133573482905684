import api from 'api';
import {buildApiSearch} from 'lib';

import {Transfer, TransferDirection} from './types';

export const fetchAll = async (
  date: string,
  direction: TransferDirection
): Promise<{transfers: Transfer[]}> => {
  const response = await api.get<{transfers: Transfer[]}>(
    `/hotels/reports/transfer/${direction}?${buildApiSearch({
      date,
    })}`
  );

  return response.data;
};

export const fetch = async (
  transfer: Transfer
): Promise<{transfers: Transfer[]}> => {
  const response = await api.get<{transfers: Transfer[]}>(transfer.links.self);

  return response.data;
};
