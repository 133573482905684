import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';

export const getAxiosTransformer = <T>(
  transformer: T | T[] | undefined
): T[] => {
  if (!transformer) return [];
  if (!Array.isArray(transformer)) return [transformer];

  return transformer;
};

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  transformResponse: [
    ...getAxiosTransformer(axios.defaults.transformResponse),
    data => camelcaseKeys(data, {deep: true}),
  ],
  transformRequest: [
    data => (typeof data === 'undefined' ? data : snakecaseKeys(data)),
    ...getAxiosTransformer(axios.defaults.transformRequest),
  ],
});

export default api;
