import React from 'react';
import {Menu} from 'grommet';
import {useTranslation} from 'react-i18next';

import {useQueryParams, useAuthentication, buildApiSearch} from 'lib';
import {ScopeNameSpace} from 'authentication/types';
import {Context as HotelsContext} from 'hotels';
import {Context as BrandsContext} from 'brands';

enum Format {
  PDF = 'pdf',
  Excel = 'xlsx',
}

interface Props {
  page: string;
}

const exportTypes: Record<ScopeNameSpace, Format[]> = {
  bookings: [Format.PDF, Format.Excel],
  bookings_guide: [Format.PDF, Format.Excel],
  rooming: [Format.PDF],
  occupancy: [],
  vouchers: [],
  products: [Format.PDF, Format.Excel],
  reports: [Format.PDF],
};

const ExportMenu: React.FC<Props> = ({page}): React.ReactElement | null => {
  const {currentHotels: hotels} = React.useContext(HotelsContext);
  const {currentBrands: brands} = React.useContext(BrandsContext);
  const {loggedIn, auth} = useAuthentication();
  const {from, to, date, order, key} = useQueryParams();
  const {t} = useTranslation();
  const namespace = page.split('/')[0] as ScopeNameSpace;

  const items = React.useMemo(() => {
    if (!loggedIn) return;

    return Object.entries(exportTypes).reduce<{
      [key: string]: {label: string; url: string}[];
    }>(
      (acc, [name, formats]) => ({
        ...acc,
        [name]: formats.map<{label: string; url: string}>((format) => {
          const search = buildApiSearch(
            {
              hotelId: hotels.map((hotel) => hotel.id),
              brandId: brands.map((brand) => brand.id),
              key,
              order,
              date,
              from,
              to,
              authorization: auth.token,
            },
            {addQueryPrefix: true}
          );

          const url = `${process.env.REACT_APP_API_BASE_URL}hotels/${page}.${format}${search}`;

          return {
            label: t(format),
            url,
          };
        }),
      }),
      {}
    );
  }, [
    loggedIn,
    t,
    page,
    hotels,
    brands,
    key,
    order,
    date,
    from,
    to,
    auth?.token,
  ]);

  if (!items) return null;
  if (!items[namespace]) return null;
  if (!items[namespace].length) return null;

  return (
    <Menu
      dropProps={{align: {top: 'bottom', left: 'left'}}}
      alignSelf="end"
      margin={{left: 'large'}}
      label={t('export')}
      items={items[namespace].map(({url, label}) => ({
        label,
        onClick: () => window.open(url, '_blank'),
      }))}
    />
  );
};

export default ExportMenu;
