import {
  BaseApiBooking,
  SortKey,
  BookingType,
  BookingGuide,
  ApiBooking,
  Booking,
  BookingSortKey,
  BookingGuideSortKey,
  ApiBookingGuide,
} from 'bookings';
import {dateComparer, stringComparer, numberComparer} from './comparers';
import SortOrder from './SortOrder';

const createBaseBookingComparer = <
  TApiBooking extends BaseApiBooking,
  TBooking extends BookingType<TApiBooking>
>(
  key: SortKey,
  order: SortOrder
): ((b1: TBooking, b2: TBooking) => number) => {
  switch (key) {
    case 'orderDate':
    case 'checkIn':
    case 'checkOut':
      return (b1, b2) => dateComparer(b1[key], b2[key], order);

    case 'orderNum':
      return (b1, b2) => stringComparer(b1[key], b2[key], order);

    case 'customerName':
      return (b1, b2) =>
        stringComparer(b1.customer.name, b2.customer.name, order);

    case 'pax':
      return (b1, b2) => numberComparer(b1.counts[key], b2.counts[key], order);

    case 'numberOfNights':
      return (b1, b2) => numberComparer(b1[key], b2[key], order);
    default:
      throw new Error('Unsupported key for comparison');
  }
};

const createBookingComparer = (
  key: BookingSortKey,
  order: SortOrder
): ((b1: Booking, b2: Booking) => number) =>
  createBaseBookingComparer<ApiBooking, Booking>(key, order);

const createBookingGuideComparer = (
  key: BookingGuideSortKey,
  order: SortOrder
): ((b1: BookingGuide, b2: BookingGuide) => number) => {
  if (key === 'transports')
    return (b1, b2) => stringComparer(b1[key], b2[key], order);

  return createBaseBookingComparer<ApiBookingGuide, BookingGuide>(key, order);
};

export const sortBookings = (
  bookings: Booking[],
  key?: BookingSortKey,
  order: SortOrder = SortOrder.Ascending
): Booking[] => {
  if (!key) {
    return bookings;
  } else {
    return bookings.sort(createBookingComparer(key, order));
  }
};

export const sortBookingsGuide = (
  bookings: BookingGuide[],
  key?: BookingGuideSortKey,
  order: SortOrder = SortOrder.Ascending
): BookingGuide[] => {
  if (!key) {
    return bookings;
  } else {
    return bookings.sort(createBookingGuideComparer(key, order));
  }
};
