import i18n from 'i18next';
import xhr from 'i18next-xhr-backend';
import {initReactI18next} from 'react-i18next';
import {format as dateFnsFormat} from 'date-fns';

i18n
  .use(xhr)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    ns: ['common', 'bookings', 'occupancy', 'authentication'],
    defaultNS: 'common',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    lng: 'en',
    react: {useSuspense: false},
    interpolation: {
      format: (value, format) => {
        if (value instanceof Date) {
          return dateFnsFormat(value, format || 'yyyy-MM-dd');
        }

        if (format === 'uppercase') {
          return value.toUpperCase();
        }

        if (format === 'lowercase') {
          return value.toLowerCase();
        }

        return value;
      },
    },
  });

export default i18n;
