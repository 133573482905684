import React from 'react';
import {Box, Text, BoxProps, Button, ButtonExtendedProps} from 'grommet';
import {Logout, StatusInfo} from 'grommet-icons';
import {useTranslation} from 'react-i18next';

import {useAuthentication} from 'lib';
import {Brand, Context as BrandsProvider} from 'brands';

import HotelMenu from './HotelMenu';
import HeaderLogo from './HeaderLogo';
import styled, {css} from 'styled-components';

const BrandButton = styled(Button)<{textColor: string} & ButtonExtendedProps>`
  padding: 4px 12px;
  ${({textColor, primary = false}) => css`
    color: ${primary ? textColor : 'black'};
  `}
`;

const isStagingOrDevelopment = ['development', 'staging'].includes(
  process.env.REACT_APP_ENV ?? ''
);

const Header: React.FC<BoxProps> = () => {
  const {logOut, auth} = useAuthentication();
  const {t} = useTranslation('common');
  const {brands, currentBrands, toggleBrand} = React.useContext(BrandsProvider);

  const internal = React.useMemo<boolean>(() => {
    if (!auth) return false;

    return auth.audience === 'guide';
  }, [auth]);

  const brandsWithSelected = React.useMemo<(Brand & {selected: boolean})[]>(
    () =>
      brands.map((brand) => ({
        ...brand,
        selected: currentBrands.includes(brand),
      })),
    [brands, currentBrands]
  );

  return (
    <Box fill="horizontal">
      <Box
        alignContent="end"
        justify="between"
        direction="row-responsive"
        flex="shrink"
        width="100%"
        gap="xlarge"
        background={isStagingOrDevelopment ? 'lightyellow' : 'light-1'}
        pad="small"
      >
        <Box direction="row" align="center" gap="small">
          <HeaderLogo />
          {internal && (
            <Box gap="small" direction="row" height="36px">
              {brandsWithSelected.map((brand) => (
                <BrandButton
                  textColor={brand.textColor}
                  color={brand.backgroundColor}
                  primary={brand.selected}
                  label={brand.code}
                  onClick={() => toggleBrand(brand)}
                  key={brand.code}
                />
              ))}
            </Box>
          )}
          <HotelMenu />
          {isStagingOrDevelopment && (
            <Box direction="row" align="center">
              <StatusInfo color="status-warning" size="16px" />
              <Text
                margin={{left: 'xsmall'}}
                size="small"
                color="status-warning"
              >
                {t('testEnvironmentMessage')}
              </Text>
            </Box>
          )}
        </Box>
        <Box justify="center">
          <Button
            fill="horizontal"
            onClick={() => logOut()}
            icon={<Logout />}
            label={t('logOut')}
            plain
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
