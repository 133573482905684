import sortBy from 'lodash.sortby';
import {Product, ProductSummary, ProductT} from 'products';

export const sortProductCategories = <T>(
  group: T[],
  callback: (item: T) => (string | number)[]
): T[] => sortBy<T>(group, callback);

export const sortParams = (
  category: ProductT,
  count: number
): (string | number)[] => [
  category.productCategory.startdate,
  category.productCategory.numberOfUnits,
  category.productCategory.unit,
  category.productCategory.description,
  category.productCategory.ageFrom,
  category.productCategory.ageTo,
  count,
];

export const sortGroupedProducts = (
  groupedProducts: Product[][]
): Product[][] =>
  sortProductCategories(groupedProducts, (products) =>
    sortParams(products[0], products.length)
  );

export const sortProductSummaries = (
  summaries: ProductSummary[]
): ProductSummary[] =>
  sortProductCategories(summaries, (summary) =>
    sortParams(summary, summary.count)
  );
