import React from 'react';
import {ButtonExtendedProps} from 'grommet';
import {useHistory, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {ScopeNameSpace} from 'authentication/types';
import DropdownMenu from 'hotels/HotelsContent/DropdownMenu';

interface Props {
  url: string;
  renderLinkContent: (namespace: ScopeNameSpace) => React.ReactElement;
}

const namespace: ScopeNameSpace = 'reports';

const ReportMenu: React.FC<Props> = ({url, renderLinkContent}) => {
  const {t} = useTranslation(['reports']);
  const {search} = useLocation();
  const {push} = useHistory();

  const items = React.useMemo<ButtonExtendedProps[]>(() => {
    return [
      {
        label: t('reports:transferArrival'),
        onClick: () => {
          push({search, pathname: `${url}/${namespace}/transfer/arrival`});
        },
      },
      {
        label: t('reports:transferDeparture'),
        onClick: () => {
          push({search, pathname: `${url}/${namespace}/transfer/departure`});
        },
      },
    ];
  }, [push, search, t, url]);

  return (
    <DropdownMenu
      namespace={namespace}
      items={items}
      renderLinkContent={renderLinkContent}
    />
  );
};

export default ReportMenu;
