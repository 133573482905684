import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faTrain,
  faPlane,
  faCar,
  faBus,
} from '@fortawesome/free-solid-svg-icons';

import {TransportType} from './types';

interface Props {
  transportType: TransportType;
}

const Transport: React.FC<Props> = ({transportType}) => {
  switch (transportType) {
    case 'bus':
      return <FontAwesomeIcon icon={faBus} />;
    case 'car':
      return <FontAwesomeIcon icon={faCar} />;
    case 'train':
      return <FontAwesomeIcon icon={faTrain} />;
    case 'plane':
      return <FontAwesomeIcon icon={faPlane} />;
    default:
      return null;
  }
};

export default React.memo(Transport);
