import groupBy from 'lodash.groupby';

import {
  Product as ProductT,
  ProductGroup as ProductGroupT,
  ProductSummary,
} from 'products';
import {productCategoryKey} from './productCategory';
import {sortGroupedProducts} from './sortProductCategories';

interface BaseItem {
  type: 'product' | 'productGroup' | 'productSummary';
  item: ProductT | ProductSummary | ProductGroupT;
}

export interface ProductItem extends BaseItem {
  type: 'product';
  item: ProductT;
}

export interface ProductSummaryItem extends BaseItem {
  type: 'productSummary';
  item: ProductSummary;
}

export interface ProductGroupItem extends BaseItem {
  type: 'productGroup';
  item: ProductGroupT;
}

export type GroupedProductItem =
  | ProductItem
  | ProductSummaryItem
  | ProductGroupItem;

export const buildProductItem = (product: ProductT): ProductItem => ({
  type: 'product',
  item: product,
});

export const buildProductSummaryItem = (
  productSummary: ProductSummary
): ProductSummaryItem => ({
  type: 'productSummary',
  item: productSummary,
});

export const buildProductGroupItem = (
  productGroup: ProductGroupT
): ProductGroupItem => ({
  type: 'productGroup',
  item: productGroup,
});

export const groupProducts = (
  products: ProductT[]
): Record<string, ProductT[]> =>
  groupBy(products, (product) => productCategoryKey(product.productCategory));

export const flatGroupedProductItems = (
  products: ProductT[],
  sortProducts: (products: ProductT[]) => ProductT[]
): GroupedProductItem[] => {
  const items: GroupedProductItem[] = [];

  sortGroupedProducts(Object.values(groupProducts(products))).forEach(
    (products) => {
      items.push(
        buildProductGroupItem({
          productCategory: products[0].productCategory,
          count: products.length,
        })
      );

      sortProducts(products).forEach((booking) => {
        items.push(buildProductItem(booking));
      });
    }
  );

  return items;
};
