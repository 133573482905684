import api from 'api';
import {Hotel} from './types';
import {buildApiSearch} from 'lib';

export const fetch = (brandIds: number[]): Promise<Hotel[]> =>
  api
    .get<Hotel[]>(
      `/hotels?${buildApiSearch({
        brandId: brandIds,
      })}`
    )
    .then(({data}) => data);
