import React from 'react';
import {render} from 'react-dom';
// @ts-ignore
// import Honeybadger from 'honeybadger-js';

import './i18n';
import App from './App';
import ErrorBoundary from 'common/ErrorBoundary';

// const honeybadger = Honeybadger.configure({
//   apiKey: '064f3e10',
//   environment: 'production',
//   disabled: process.env.NODE_ENV === 'development',
// });

render(
  // <ErrorBoundary honeybadger={honeybadger}>
  <ErrorBoundary>
    <App />,
  </ErrorBoundary>,

  document.getElementById('root')
);
