import React from 'react';
import debounce from 'lodash.debounce';
import {Drop, DropProps, Box, BoxProps, Text} from 'grommet';

interface Props
  extends Pick<
      BoxProps,
      'margin' | 'elevation' | 'pad' | 'background' | 'fill'
    >,
    Pick<DropProps, 'align'> {
  title: string;
  children?: React.ReactNode;
  delay?: number;
}

const Tooltip: React.FC<Props> = ({
  title,
  align,
  children,
  fill,
  delay,
  ...props
}) => {
  const ref = React.useRef();
  const [isOpen, setIsOpen] = React.useState(false);

  const show = React.useMemo(
    () => debounce(() => setIsOpen(true), delay),
    [delay]
  );

  const hide = React.useCallback(() => {
    show.cancel();
    setIsOpen(false);
  }, [show]);

  return (
    <>
      <Box fill={fill} onMouseOver={show} onMouseLeave={hide} ref={ref as any}>
        {children}
      </Box>
      {ref.current && isOpen && (
        <Drop plain target={ref.current} align={align}>
          <Box {...props}>
            <Text size="small" color="light-1">
              {title}
            </Text>
          </Box>
        </Drop>
      )}
    </>
  );
};

Tooltip.defaultProps = {
  background: 'dark-3',
  margin: 'small',
  elevation: 'small',
  pad: 'small',
};

export default Tooltip;
