import React from 'react';
import {Anchor, ButtonExtendedProps, Menu} from 'grommet';

import {ScopeNameSpace} from 'authentication/types';

interface Props {
  namespace: ScopeNameSpace;
  renderLinkContent: (namespace: ScopeNameSpace) => React.ReactElement;
  items: ButtonExtendedProps[];
}

const DropdownMenu: React.FC<Props> = ({
  namespace,
  renderLinkContent,
  items,
}): React.ReactElement => {
  return (
    <Menu
      dropProps={{align: {top: 'bottom', left: 'left'}}}
      label="Menu"
      items={items}
      margin={{right: 'medium'}}
    >
      <Anchor>{renderLinkContent(namespace)}</Anchor>
    </Menu>
  );
};

export default DropdownMenu;
