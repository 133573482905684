import React, {useRef} from 'react';

import Context from './Context';
import * as api from './api';
import * as actions from './actions';
import reducer, {initialState} from './reducer';

const Provider: React.FC = ({children}) => {
  const isMounted = useRef(false);
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    if (isMounted.current) return;
    isMounted.current = true;

    api.fetch().then((data) => {
      if (isMounted.current) dispatch(actions.success(data));
    });

    return () => {
      isMounted.current = true;
    };
  }, []);

  return <Context.Provider value={state}>{children}</Context.Provider>;
};

Provider.displayName = 'SettingsProvider';

export default Provider;
