import React from 'react';
import {Route} from 'react-router-dom';

import useSearch from 'lib/hooks/useSearch';

import SearchBox from './components/SearchBox';

const SearchPage = () => {
  const {search, setSearch} = useSearch();
  const paths = ['bookings_guide'];

  return (
    <Route
      exact
      path={`/hotels/:page(${paths.join('|')})`}
      render={(props) => <SearchBox value={search} handleSearch={setSearch} />}
    />
  );
};

export default SearchPage;
