import {State} from './reducer';
import {Brand} from './types';

export const getBrandById = (
  state: State,
  brandId: number | number[]
): Brand[] => {
  const id = typeof brandId === 'string' ? parseInt(brandId, 10) : brandId;

  if (Array.isArray(brandId)) {
    if (brandId.length === 0) return state.brands;

    return state.brands.filter((brand) => brandId.includes(brand.id));
  }

  const brand = state.brands.find((brand) => brand.id === id);

  return brand ? [brand] : [];
};
