import React from 'react';

import {Hotel} from './types';
import {Action} from './actions';

export interface State {
  isLoading: boolean;
  hotels: Hotel[];
}

export const initialState: State = {
  isLoading: true,
  hotels: [],
};

const reducer: React.Reducer<State, Action> = (state, action): State => {
  switch (action.type) {
    case 'success':
      return {
        ...state,
        isLoading: false,
        hotels: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
