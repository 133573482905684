import {addWeeks, subWeeks, setDay, closestTo} from 'date-fns';

const closestDay = (date: Date, day: number): Date =>
  // The closest day to date is either in the current, next or previous week.
  closestTo(date, [
    setDay(date, day, {weekStartsOn: 1}),
    setDay(addWeeks(date, 1), day, {weekStartsOn: 1}),
    setDay(subWeeks(date, 1), day, {weekStartsOn: 1}),
  ]);

export default closestDay;
