import React from 'react';
import {directions} from 'transfer';
import useAuthentication from './useAuthentication';
import useSettings from './useSettings';

const usePath = (): string[] => {
  const {allowedNamespaces} = useAuthentication();
  const {settings} = useSettings();

  const paths = React.useMemo<string[]>(() => {
    if (!settings) return [];

    const {productTypes} = settings;

    return allowedNamespaces
      .map((namespace) => {
        switch (namespace) {
          case 'products':
            return productTypes
              .map((productType) => [
                `${namespace}/${productType.code}`,
                `${namespace}/${productType.code}/summary`,
              ])
              .flat();
          case 'reports':
            return directions.map(
              (direction) => `${namespace}/transfer/${direction}`
            );
          default:
            return namespace;
        }
      })
      .flat();
  }, [allowedNamespaces, settings]);

  return paths;
};

export default usePath;
