import {createContext} from 'react';
import {initialState} from './reducer';
import {State} from './types';

export interface AuthenticationContext extends State {
  logIn: (username: string, password: string) => void;
  logOut: () => void;
}

const Context = createContext<AuthenticationContext>({
  logIn: (_username: string, _password: string) => {},
  logOut: () => {},
  ...initialState,
});

Context.displayName = 'AuthenticationContext';

export default Context;
