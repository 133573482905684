import React from 'react';
import {State} from './types';

const Context = React.createContext<State>({
  type: 'period',
  from: new Date(),
  to: new Date(),
});

Context.displayName = 'DateContext';

export default Context;
