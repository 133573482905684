import React from 'react';

import {Hotel} from './types';
import {initialState} from './reducer';

const Context = React.createContext<
  typeof initialState & {
    currentHotels: Hotel[];
    findHotel: (hotelId: number) => Hotel | undefined;
  }
>({...initialState, currentHotels: [], findHotel: () => undefined});

Context.displayName = 'HotelsContext';

export default Context;
