import {ThemeType} from 'grommet';

const theme: ThemeType = {
  global: {
    colors: {
      brand: '#127C9D',
      focus: 'brand',
    },
    font: {
      family: "'Roboto', 'Helvetica Neue', sans-serif",
    },
  },
  button: {
    border: {
      radius: '5px',
    },
  },
  anchor: {
    fontWeight: 400,
  },
};

export default theme;
