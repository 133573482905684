import React from 'react';
import styled, {keyframes} from 'styled-components';
import {Text, Box} from 'grommet';
import {useTranslation} from 'react-i18next';

const spinnerAnimation = keyframes`
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1.0);
    opacity: 0;
  }
`;

const Spinner = styled(Box)`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  animation: ${spinnerAnimation} 1s infinite ease-in-out;
  will-change: opacity, transform;
`;

interface Props {
  message?: string;
}

const Loading: React.FC<Props> = ({message}) => {
  const [show, setShow] = React.useState(false);
  const {t} = useTranslation('common');

  React.useEffect(() => {
    const timer = setTimeout(() => setShow(true), 300);

    return () => clearTimeout(timer);
  }, [setShow]);

  return show ? (
    <Box
      fill="horizontal"
      direction="row"
      alignContent="center"
      justify="center"
      margin="medium"
    >
      <Spinner background="dark-6" margin={{right: 'small'}} />
      <Text textAlign="center">{message || t('loading')}</Text>
    </Box>
  ) : null;
};

export default Loading;
