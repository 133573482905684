import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {ButtonExtendedProps} from 'grommet';

import {useSettings} from 'lib';
import {ScopeNameSpace} from 'authentication/types';
import DropdownMenu from 'hotels/HotelsContent/DropdownMenu';

interface Props {
  url: string;
  renderLinkContent: (namespace: ScopeNameSpace) => React.ReactElement;
}

const namespace: ScopeNameSpace = 'products';

const ProductMenu: React.FC<Props> = ({
  url,
  renderLinkContent,
}): React.ReactElement => {
  const {settings} = useSettings();
  const {search} = useLocation();
  const {push} = useHistory();

  const items = React.useMemo<ButtonExtendedProps[]>(() => {
    if (!settings) return [];

    const {productTypes} = settings;

    return productTypes.map<ButtonExtendedProps>((productType) => {
      const pathname = `${url}/${namespace}/${productType.code}${
        productType.summary ? '/summary' : ''
      }`;

      return {
        label: productType.name,
        onClick: () => {
          push({search, pathname});
        },
      };
    });
  }, [push, settings, search, url]);

  return (
    <DropdownMenu
      namespace={namespace}
      items={items}
      renderLinkContent={renderLinkContent}
    />
  );
};

export default ProductMenu;
