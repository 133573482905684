import React from 'react';
import {parseISO, format} from 'date-fns';
import {Calendar, TextInput, Drop} from 'grommet';

interface Props {
  value: Date;
  onSelect(date: Date): void;
  formatString: string;
}

const RangePicker: React.FC<Props> = ({value, onSelect, formatString}) => {
  const input = React.useRef();
  const [isOpen, setIsOpen] = React.useState(false);
  const open = React.useCallback(() => setIsOpen(true), [setIsOpen]);
  const close = React.useCallback(() => setIsOpen(false), [setIsOpen]);

  const handleSelect = React.useCallback(
    (value) => {
      onSelect(parseISO(value));
      close();
    },
    [close, onSelect]
  );

  return (
    <>
      <TextInput
        readOnly
        size="small"
        value={format(value, formatString)}
        onFocus={open}
        ref={input as any}
      />
      {isOpen && input.current && (
        <Drop
          target={input.current}
          onClickOutside={close}
          align={{top: 'bottom', left: 'left'}}
        >
          <Calendar
            date={format(value, 'yyyy-MM-dd')}
            animate={false}
            firstDayOfWeek={1}
            onSelect={handleSelect}
          />
        </Drop>
      )}
    </>
  );
};

export default RangePicker;
