import {Product, SortKey} from 'products';
import {numberComparer} from './comparers';
import SortOrder from './SortOrder';

const createComparer = (
  key: SortKey,
  order: SortOrder
): ((p1: Product, p2: Product) => number) => {
  switch (key) {
    case 'accommodationId':
      return (p1, p2) => numberComparer(p1[key], p2[key], order);
  }
};

export const sortProducts = (
  products: Product[],
  key?: SortKey,
  order: SortOrder = SortOrder.Ascending
) => {
  if (!key) {
    return products;
  } else {
    return products.sort(createComparer(key, order));
  }
};
