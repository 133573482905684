import {Action, State} from './types';

export const initialState: State = {
  ready: false,
  auth: null,
  isLoading: false,
  error: null,
};

const reducer: React.Reducer<State, Action> = (state, action): State => {
  switch (action.type) {
    case 'authenticate':
      return {...state, error: null, isLoading: true};
    case 'success':
      return {
        ...state,
        ready: true,
        isLoading: false,
        error: null,
        auth: action.payload,
      };
    case 'error':
      return {...state, isLoading: false, error: action.payload};
    case 'ready':
      return {...state, ready: true};
    case 'logout':
      return {...initialState, ready: true};
    default:
      return state;
  }
};

export default reducer;
