import React from 'react';
import {useHistory} from 'react-router-dom';

import {
  appendSearch,
  parseQueryParameter,
  useAuthentication,
  useQueryParams,
} from 'lib';

import * as api from './api';
import * as actions from './actions';
import * as selectors from './selectors';
import reducer, {initialState} from './reducer';
import Context from './Context';
import {Brand} from './types';

const Provider: React.FC = ({children}) => {
  const {auth} = useAuthentication();
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const {brandId: brandIds} = useQueryParams({brandId: parseQueryParameter});
  const {
    replace,
    location: {search, pathname},
  } = useHistory();

  const isMounted = React.useRef(false);

  const isGuide = React.useMemo<boolean>(() => {
    if (!auth) return false;

    return auth.audience === 'guide';
  }, [auth]);

  // Fetch all brands
  React.useEffect(() => {
    if (!isGuide) return;
    if (isMounted.current) return;
    isMounted.current = true;

    api.fetch().then((brands) => {
      if (isMounted.current) dispatch(actions.success(brands));
    });

    return () => {
      isMounted.current = false;
    };
  }, [isGuide]);

  // Based on brandId in URL select the current brand.
  const currentBrands = React.useMemo(
    () => selectors.getBrandById(state, brandIds),
    [brandIds, state]
  );

  // Toggle brand and persist selected brands in the url
  const toggleBrand = React.useCallback(
    (brand: Brand) => {
      const includes = currentBrands.map((b) => b.id).includes(brand.id);

      const newBrands: Brand[] = includes
        ? currentBrands.filter((b) => b.id !== brand.id)
        : [...currentBrands, brand];

      const brandIds =
        newBrands.length !== state.brands.length
          ? newBrands.map((b) => b.id)
          : undefined;

      replace({
        search: appendSearch(search, {brandId: brandIds}),
        pathname,
      });
    },
    [currentBrands, pathname, replace, search, state.brands]
  );

  return (
    <Context.Provider value={{currentBrands, toggleBrand, ...state}}>
      {children}
    </Context.Provider>
  );
};

Provider.displayName = 'BrandsProvider';

export default Provider;
