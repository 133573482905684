import React from 'react';
import {Redirect} from 'react-router-dom';
import {Box, Button, Form, FormExtendedEvent, FormField, Text} from 'grommet';
import {StatusInfo, Login as LoginIcon} from 'grommet-icons';
import {useTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet';

import {useAuthentication, useValidation} from 'lib';
import {LoginFormData} from './types';
import validationSchema from './schema';

const initialValue: LoginFormData = {username: '', password: ''};

const Login = () => {
  const {logIn, error, isLoading, loggedIn} = useAuthentication();
  const {t} = useTranslation(['authentication', 'common']);
  const [formData, setFormData] = React.useState(initialValue);
  const [isValid, validate] = useValidation<LoginFormData>(validationSchema);

  const handleChange = (value: LoginFormData): void => {
    setFormData(value);
    validate(value);
  };

  const handleSubmit = ({value}: FormExtendedEvent<LoginFormData, Element>) => {
    logIn(value.username, value.password);
  };

  if (loggedIn) return <Redirect to="/hotels" />;

  return (
    <Box justify="center" align="center">
      <Helmet>
        <title>{t('authentication:logIn')}</title>
      </Helmet>
      <Box
        width="medium"
        pad="medium"
        margin={{top: 'xlarge'}}
        elevation="medium"
        animation="fadeIn"
      >
        {process.env.NODE_ENV === 'production' &&
          process.env.REACT_APP_ENV === 'staging' && (
            <Box margin={{bottom: 'small'}} direction="row" align="center">
              <StatusInfo color="status-warning" size="16px" />
              <Text
                margin={{left: 'xsmall'}}
                size="small"
                color="status-warning"
              >
                {t('common:testEnvironmentMessage')}
              </Text>
            </Box>
          )}
        <Form
          onSubmit={handleSubmit}
          value={formData}
          onChange={(value: LoginFormData) => handleChange(value)}
        >
          <FormField
            autoFocus
            id="username"
            htmlFor="username"
            name="username"
            label={t('username')}
          />
          <FormField
            type="password"
            id="password"
            htmlFor="password"
            name="password"
            label={t('password')}
          />
          {error && (
            <Box>
              <Text color="status-error" margin={{vertical: 'small'}}>
                {error}
              </Text>
            </Box>
          )}
          <Button
            fill
            primary
            type="submit"
            label={t('logIn')}
            icon={<LoginIcon />}
            disabled={!isValid || isLoading}
          />
        </Form>
      </Box>
    </Box>
  );
};

export default Login;
