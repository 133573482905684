import qs, {IParseOptions, IStringifyOptions, ParsedQs} from 'qs';
import snakecaseKeys from 'snakecase-keys';

export type ParsedSearch = ParsedQs;

export const buildApiSearch = (
  object: any,
  options: IStringifyOptions = {}
): string => buildSearch(snakecaseKeys(object), options);

export const buildSearch = (
  object: any,
  options: IStringifyOptions = {}
): string => qs.stringify(object, {...options, arrayFormat: 'comma'});

export const parseSearch = (
  search: string,
  options: IParseOptions & {decoder?: never | undefined} = {}
): ParsedSearch => {
  const searchQuery = search.includes('?') ? search.split('?')[1] : search;

  return qs.parse(searchQuery, {...options, comma: true});
};

export const appendApiSearch = (
  search: string,
  object: any,
  options: IStringifyOptions = {}
): string => {
  const searchObject = {
    ...parseSearch(search),
    ...object,
  };

  return buildSearch(snakecaseKeys(searchObject), options);
};

export const appendSearch = (search: string, object: any) => {
  const searchObject = {
    ...parseSearch(search),
    ...object,
  };

  return buildSearch(searchObject);
};
