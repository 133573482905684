export interface Action<T extends string> {
  type: T;
}
export interface ActionWithPayload<T extends string, P> extends Action<T> {
  payload: P;
}
export interface ActionWithMeta<T extends string, P, M>
  extends ActionWithPayload<T, P> {
  meta: M;
}

export function createAction<T extends string>(type: T): Action<T>;
export function createAction<T extends string, P>(
  type: T,
  payload: P
): ActionWithPayload<T, P>;
export function createAction<T extends string, P, M>(
  type: T,
  payload: P,
  meta: M
): ActionWithMeta<T, P, M>;
export function createAction<T extends string, P, M>(
  type: T,
  payload?: P,
  meta?: M
) {
  if (typeof payload === 'undefined' && typeof meta === 'undefined') {
    return {type};
  } else if (typeof meta === 'undefined') {
    return {type, payload};
  } else {
    return {type, payload, meta};
  }
}
