import {ScopeNameSpace} from './types';

const namespaces: ScopeNameSpace[] = [
  'bookings',
  'bookings_guide',
  'rooming',
  'occupancy',
  'products',
  'vouchers',
  'reports',
];

export default namespaces;
