import type {ScopeNameSpace, ScopePermission} from './types';

class Scope {
  private _namespace: ScopeNameSpace;
  private _hasReadPermission: boolean;
  private _hasWritePermission: boolean;
  private _hasAdminPermission: boolean;

  /**
   * Constructs a Scope object
   */
  constructor(namespace: ScopeNameSpace, permissions: ScopePermission[]) {
    this._namespace = namespace;
    this._hasReadPermission = permissions.includes('read');
    this._hasWritePermission = permissions.includes('write');
    this._hasAdminPermission = permissions.includes('admin');
  }

  public get namespace(): ScopeNameSpace {
    return this._namespace;
  }

  public get hasReadPermission(): boolean {
    return this._hasReadPermission;
  }

  public get hasWritePermission(): boolean {
    return this._hasWritePermission;
  }

  public get hasAdminPermission(): boolean {
    return this._hasAdminPermission;
  }
}

export default Scope;
