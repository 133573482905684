import React from 'react';
import {useTranslation} from 'react-i18next';

import apiClient from 'api';
import * as api from './api';
import Context from './Context';
import {Provider as SettingsProvider} from 'settings';
import reducer, {initialState} from './reducer';
import {
  error,
  success as successAction,
  ready as readyAction,
  authenticate as authenticateAction,
  logout as logoutAction,
} from './actions';
import {parseToken} from './authToken';
import AuthenticationError from './AuthenticationError';
import debounce from 'lodash.debounce';
import {AxiosError} from 'axios';

const getTokenKey = (): string => {
  switch (process.env.REACT_APP_ENV) {
    case 'development':
      return 'DEVELOPMENT_token';
    case 'staging':
      return 'STAGING_token';
    case 'production':
      return 'token';
    case 'test':
      return 'TEST_token';

    default:
      throw new Error('Unsupported environment');
  }
};

function setToken(token: string) {
  localStorage.setItem(getTokenKey(), token);
  apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

function removeToken() {
  localStorage.removeItem(getTokenKey());
  delete apiClient.defaults.headers.common['Authorization'];
}

const Provider: React.FC = ({children}) => {
  const isMounted = React.useRef(false);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const {t} = useTranslation('authentication');
  const debouncedAuthenticate = React.useRef(
    debounce<(...args: Parameters<typeof api.authenticate>) => void>(
      (username: string, password: string) =>
        api
          .authenticate(username, password)
          .then(({authToken}) => {
            setToken(authToken);
            success(authToken);
          })
          .catch((e: AxiosError | Error) => {
            if (e.name === 'AxiosError') {
              return dispatch(error(t('wrongUsernameOrPassword')));
            }

            return Promise.reject(e);
          }),
      100
    )
  );

  const success = React.useCallback(
    (token: string): void => {
      try {
        const auth = parseToken(token, {
          validateIssuer: true,
          validateExpiration: true,
          validateNotBefore: true,
        });

        dispatch(successAction(auth));
      } catch (e) {
        if (e instanceof AuthenticationError) {
          dispatch(error(t('wrongUsernameOrPassword')));
        }
      }
    },
    [t]
  );

  // Token validation
  React.useEffect(() => {
    if (isMounted.current) return;

    isMounted.current = true;

    const token = localStorage.getItem(getTokenKey());

    if (token) {
      api
        .validate(token)
        .then(({authToken}) => {
          setToken(authToken);
          success(authToken);
        })
        .catch(() => {
          removeToken();
          dispatch(readyAction());
        });
    } else {
      dispatch(readyAction());
    }
  }, [success]);

  React.useEffect(() => {
    if (!state.auth) {
      removeToken();
    }
  }, [state]);

  const logIn = React.useCallback((username: string, password: string) => {
    dispatch(authenticateAction());
    debouncedAuthenticate.current(username, password);
  }, []);

  const logOut = React.useCallback(() => {
    dispatch(logoutAction());
  }, [dispatch]);

  return (
    <Context.Provider value={{...state, logIn, logOut}}>
      {state.ready && state.auth ? (
        <SettingsProvider>{children}</SettingsProvider>
      ) : (
        children
      )}
    </Context.Provider>
  );
};

Provider.displayName = 'AuthenticationProvider';

export default Provider;
