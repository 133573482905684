import {compareAsc, compareDesc} from 'date-fns';

import SortOrder from './SortOrder';

export const numberComparer = (
  number1: number,
  number2: number,
  order: SortOrder
): number =>
  (order === SortOrder.Ascending ? number1 : number2) -
  (order === SortOrder.Ascending ? number2 : number1);

export const stringComparer = (
  string1: string,
  string2: string,
  order: SortOrder
): number =>
  (order === SortOrder.Ascending ? string1 : string2).localeCompare(
    order === SortOrder.Ascending ? string2 : string1
  );

export const dateComparer = (
  date1: Date,
  date2: Date,
  order: SortOrder
): number =>
  (order === SortOrder.Ascending ? compareAsc : compareDesc)(date1, date2);
