import api from 'api';

export const validate = (token: string): Promise<{authToken: string}> =>
  api
    .post<{authToken: string}>(`/validate_token`, {authToken: token})
    .then(({data}) => data);

export const authenticate = (
  username: string,
  password: string
): Promise<{authToken: string}> =>
  api
    .post<{authToken: string}>('/authenticate', {username, password})
    .then(({data}) => data);
