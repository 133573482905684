import React from 'react';

import {Transfer} from './types';
import {Action} from './actions';

export interface State {
  isLoading: boolean;
  error: string | null;
  transfers: Transfer[];
}

export const initialState: State = {
  isLoading: false,
  error: null,
  transfers: [],
};

const reducer: React.Reducer<State, Action> = (state, action): State => {
  switch (action.type) {
    case 'request':
      return {
        ...state,
        isLoading: true,
      };
    case 'success':
      return {
        ...state,
        error: null,
        isLoading: false,
        transfers: action.payload.transfers,
      };
    case 'error':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
