import React from 'react';
import {Box, TextInput, Text} from 'grommet';
import {useTranslation} from 'react-i18next';

interface Props {
  value: string;
  handleSearch: (value: string) => void;
}

const SearchBox: React.FC<Props> = ({value, handleSearch}) => {
  const {t} = useTranslation('common');

  return (
    <Box margin={{left: 'medium'}} width="20rem" alignSelf="end">
      <Text
        size="small"
        color="dark-3"
        weight="bold"
        margin={{bottom: 'xsmall'}}
      >
        {t('search').toUpperCase()}
      </Text>
      <TextInput
        size="small"
        value={value}
        onChange={(event) => handleSearch(event.target.value)}
      />
    </Box>
  );
};

export default SearchBox;
