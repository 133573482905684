import {State} from './reducer';
import {Hotel} from './types';

export const getHotelsById = (
  state: State,
  hotelId: number | number[]
): Hotel[] => {
  const id = typeof hotelId === 'string' ? parseInt(hotelId, 10) : hotelId;

  if (Array.isArray(hotelId)) {
    if (hotelId.length === 0) return state.hotels;

    return state.hotels.filter((hotel) => hotelId.includes(hotel.id));
  }

  const hotel = state.hotels.find((hotel) => hotel.id === id);

  return hotel ? [hotel] : [];
};
