import React from 'react';
import {useTranslation} from 'react-i18next';
import {Box, Text, Button} from 'grommet';
import {Next, Previous} from 'grommet-icons';

import {RangePicker} from 'common';

interface DateRangePageProps {
  handleNext: () => void;
  handlePrevious: () => void;
  handleFromSelect: (value: Date) => void;
  handleToSelect: (value: Date) => void;
  from: Date;
  to: Date;
}

const DateRangePage: React.FC<DateRangePageProps> = ({
  from,
  to,
  handleNext,
  handlePrevious,
  handleFromSelect,
  handleToSelect,
}) => {
  const {t} = useTranslation('common');

  return (
    <Box direction="row" justify="between" margin={{bottom: 'medium'}}>
      <Box direction="row">
        <Button
          plain
          onClick={handlePrevious}
          margin={{right: 'small', bottom: 'small'}}
          icon={<Previous />}
          alignSelf="end"
        />
        <Box margin={{right: 'medium'}}>
          <Text
            size="small"
            color="dark-3"
            weight="bold"
            margin={{bottom: 'xsmall'}}
          >
            {t('checkInFrom').toUpperCase()}
          </Text>
          <RangePicker
            formatString="dd-MM-yyyy"
            value={from}
            onSelect={handleFromSelect}
          />
        </Box>
        <Box>
          <Text
            size="small"
            color="dark-3"
            weight="bold"
            margin={{bottom: 'xsmall'}}
          >
            {t('checkInTo').toUpperCase()}
          </Text>
          <RangePicker
            formatString="dd-MM-yyyy"
            value={to}
            onSelect={handleToSelect}
          />
        </Box>
        <Button
          plain
          onClick={handleNext}
          margin={{left: 'small', bottom: 'small'}}
          icon={<Next />}
          alignSelf="end"
        />
      </Box>
    </Box>
  );
};

export default DateRangePage;
