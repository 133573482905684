import React from 'react';

import {Context as SearchContext, State} from 'hotels/search';

const useSearch = (): State => {
  const state = React.useContext(SearchContext);

  return state;
};

export default useSearch;
