import React from 'react';
import AuthenticationContext, {
  AuthenticationContext as AuthenticationContextT,
} from 'authentication/Context';
import {AuthObject, ScopeNameSpace} from 'authentication/types';
import namespaces from 'authentication/namespaces';

interface BaseAuthHook extends AuthenticationContextT {
  loggedIn: boolean;
  allowedNamespaces: ScopeNameSpace[];
}

interface LoggedInAuthHook extends BaseAuthHook {
  loggedIn: true;
  auth: AuthObject;
}

interface NotLoggedInAuthHook extends BaseAuthHook {
  loggedIn: false;
  auth: null;
}

type AuthHook = LoggedInAuthHook | NotLoggedInAuthHook;

const useAuthentication = (): AuthHook => {
  const context = React.useContext(AuthenticationContext);
  const allowedNamespaces = React.useMemo<ScopeNameSpace[]>(() => {
    if (!context.auth) return [];

    const {scopes} = context.auth;

    return namespaces.filter((namespace) => scopes.has(namespace));
  }, [context.auth]);

  if (!context?.auth)
    return {
      loggedIn: false,
      ...context,
      auth: null,
      allowedNamespaces,
    } as NotLoggedInAuthHook;

  return {
    loggedIn: true,
    allowedNamespaces,
    ...context,
  } as LoggedInAuthHook;
};

export default useAuthentication;
