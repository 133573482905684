import React from 'react';
import {BoxProps, Menu, Text, Box, ButtonExtendedProps} from 'grommet';
import {useTranslation} from 'react-i18next';
import {useLocation, useHistory, useRouteMatch} from 'react-router-dom';

import {buildSearch, parseSearch, useAuthentication} from 'lib';
import HotelsContext from '../Context';
import {Hotel} from 'hotels/types';

const HotelMenu: React.FC<BoxProps> = () => {
  const {search} = useLocation();
  const {allowedNamespaces} = useAuthentication();
  const {push} = useHistory();
  const match = useRouteMatch<{page: string}>(
    `/hotels/:page(${allowedNamespaces.join('|')})`
  );
  const {isLoading, hotels, currentHotels} = React.useContext(HotelsContext);
  const {t} = useTranslation('common');
  const menuLabel = React.useMemo(() => {
    if (isLoading) return t('fetching', {subject: t('hotels')});

    if (currentHotels.length === 0) return 'Menu';
    if (currentHotels.length === 1) return currentHotels[0].name;

    return 'All';
  }, [isLoading, t, currentHotels]);

  const handleClick = React.useCallback(
    (hotels?: Hotel | Hotel[]) => {
      if (!match) return null;

      const parsedSearch = parseSearch(search);
      if (hotels) {
        parsedSearch.hotelId = Array.isArray(hotels)
          ? hotels.map((hotel) => hotel.id.toString())
          : hotels.id.toString();
      } else if (parsedSearch.hotelId) {
        delete parsedSearch.hotelId;
      }

      push({
        search: buildSearch(parsedSearch),
        pathname: `/hotels/${match.params.page}`,
      });
    },
    [match, push, search]
  );

  const menuItems = React.useMemo<ButtonExtendedProps[]>(() => {
    const items: ButtonExtendedProps[] = [];
    if (!match) return [];

    if (hotels.length > 1) {
      items.push({
        label: 'All',
        onClick: () => handleClick(),
      });
    }

    items.push(
      ...hotels.map<ButtonExtendedProps>((hotel) => ({
        label: hotel.name,
        onClick: () => handleClick(hotel),
      }))
    );

    return items;
  }, [match, hotels, handleClick]);

  if (menuItems.length === 1) {
    return (
      <Box>
        <Text>{menuItems[0].label}</Text>
      </Box>
    );
  }

  return (
    <Menu
      label={menuLabel}
      items={menuItems}
      disabled={isLoading}
      dropProps={{align: {top: 'bottom', left: 'left'}}}
    />
  );
};

export default HotelMenu;
