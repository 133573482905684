import React from 'react';

import {Brand} from './types';
import {initialState} from './reducer';

const Context = React.createContext<
  typeof initialState & {
    currentBrands: Brand[];
    toggleBrand: (brand: Brand) => void;
  }
>({...initialState, currentBrands: [], toggleBrand: (_brand) => {}});

Context.displayName = 'BrandsContext';

export default Context;
