import React, {useState} from 'react';

import Context from './Context';

const Provider: React.FC = ({children}) => {
  const [search, setSearch] = useState<string>('');

  return (
    <>
      <Context.Provider value={{search, setSearch}}>
        {children}
      </Context.Provider>
    </>
  );
};

export default Provider;
