import React from 'react';
import {Image} from 'grommet';

import {Context as SettingsContext} from 'settings';

const HeaderLogo: React.FC = () => {
  const {isLoading, settings} = React.useContext(SettingsContext);

  return !isLoading && settings && settings.headerImageUrl ? (
    <Image
      height="50px"
      width="192px"
      fit="contain"
      src={settings.headerImageUrl}
    />
  ) : null;
};

export default HeaderLogo;
