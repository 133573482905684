import React from 'react';
import {useLocation} from 'react-router-dom';
import isEqual from 'lodash.isequal';

import {ParsedSearch, parseSearch} from 'lib/searchQuery';

type Parser<T = any, S = any> = (value: S) => T;
type Parsers<T = {}, S = string> = {
  [K in keyof T]: Parser<T[K], S>;
};

const useQueryParams = <P = {}, T = {}>(
  parsers?: Parsers<T>
): T & P & Record<string, string> => {
  const {search} = useLocation();

  const prevParsers = React.useRef<Parsers<T> | null>(null);

  const parsersEqual = React.useMemo(
    () => isEqual(parsers, prevParsers.current),
    [parsers, prevParsers]
  );

  const params = React.useMemo(() => {
    const parsedSearch = parseSearch(search, {
      ignoreQueryPrefix: true,
    }) as any;

    if (typeof parsers === 'undefined') {
      return parsedSearch;
    } else {
      prevParsers.current = parsers;

      return Object.entries<Parser>(parsers).reduce<Partial<ParsedSearch> & T>(
        (acc, [key, parser]) => ({
          ...acc,
          [key]: parser(acc[key]),
        }),
        parsedSearch
      );
    }
    // eslint-disable-next-line
  }, [search, parsersEqual]);

  return params;
};

export default useQueryParams;
