import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import {Grommet} from 'grommet';
import WebFont from 'webfontloader';

import {Provider as AuthenticationProvider, Login} from 'authentication';
import {Hotels} from 'hotels';
import theme from './theme';

const App = () => {
  React.useEffect(() => {
    WebFont.load({
      google: {
        families: ['Roboto:300,400,700,900'],
      },
    });
  }, []);

  return (
    <Grommet theme={theme}>
      <AuthenticationProvider>
        <Router>
          <Switch>
            <Route path="/hotels" component={Hotels} />
            <Route path="/login" component={Login} />
            <Route render={() => <Redirect to="/login" />} />
          </Switch>
        </Router>
      </AuthenticationProvider>
    </Grommet>
  );
};

export default App;
