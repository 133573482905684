import React from 'react';
import {useRouteMatch, useHistory} from 'react-router-dom';

import {Context as BrandsContext} from 'brands';
import {
  parseQueryParameter,
  useAuthentication,
  usePrevious,
  useQueryParams,
} from 'lib';

import * as api from './api';
import * as actions from './actions';
import * as selectors from './selectors';
import reducer, {initialState} from './reducer';
import Context from './Context';
import {Hotel} from './types';
import isEqual from 'lodash.isequal';
import debounce from 'lodash.debounce';

const Provider: React.FC = ({children}) => {
  const {allowedNamespaces} = useAuthentication();
  const {currentBrands: brands} = React.useContext(BrandsContext);
  const prevBrands = usePrevious(brands);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const match = useRouteMatch('/hotels/:namespace');
  const {hotelId: hotelIds} = useQueryParams({hotelId: parseQueryParameter});
  const {
    replace,
    location: {search},
  } = useHistory();

  const isRedirecting = React.useRef(false);

  const debouncedFetch = React.useRef(
    debounce<(...args: Parameters<typeof api.fetch>) => void>((brandIds) => {
      api.fetch(brandIds).then((hotels) => {
        dispatch(actions.success(hotels));
      });
    }, 500)
  );

  // Fetch all hotels
  React.useEffect(() => {
    if (isEqual(prevBrands, brands)) return;

    debouncedFetch.current(brands.map((brand) => brand.id));
  }, [brands, prevBrands]);

  // Based on hotelId in URL select the current hotel.
  const currentHotels = React.useMemo<Hotel[]>(
    () => selectors.getHotelsById(state, hotelIds),
    [hotelIds, state]
  );

  // Ensure that hotelIds are persisted in the url
  React.useEffect(() => {
    if (match) return;
    if (!isRedirecting.current) {
      isRedirecting.current = true;

      replace({
        search,
        pathname: `/hotels/${allowedNamespaces[0]}`,
      });

      isRedirecting.current = false;
    }
  }, [
    allowedNamespaces,
    currentHotels.length,
    match,
    replace,
    search,
    state.hotels,
  ]);

  const findHotel = React.useCallback(
    (hotelId: number): Hotel | undefined =>
      selectors.getHotelsById(state, hotelId)[0],
    [state]
  );

  return (
    <Context.Provider value={{currentHotels, findHotel, ...state}}>
      {children}
    </Context.Provider>
  );
};

Provider.displayName = 'HotelsProvider';

export default Provider;
