import React from 'react';
import SettingsContext from 'settings/Context';
import {State} from 'settings/reducer';

const useSettings = (): State => {
  const context = React.useContext(SettingsContext);

  return context;
};

export default useSettings;
