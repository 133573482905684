import {Notifier} from '@airbrake/browser';

const env = process.env.REACT_APP_ENV || 'development';
const notificationAllowed = ['production', 'staging'].includes(env);

const airbrake = new Notifier({
  projectId: 468492,
  projectKey: '2a0202f173c11dbc5810d4a25b6a9023',
  environment: env,
});

airbrake.addFilter((notice) => {
  if (!notificationAllowed) {
    return null;
  }
  return notice;
});

export default airbrake;
