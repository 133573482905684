import {Action} from './actions';
import {Settings} from './types';

export interface State {
  settings: Settings | null;
  isLoading: boolean;
}

export const initialState: State = {
  settings: null,
  isLoading: true,
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'success':
      return {isLoading: false, settings: action.payload};
    default:
      return state;
  }
};

export default reducer;
