import React from 'react';
import {State} from './types';

const Context = React.createContext<State>({
  search: '',
  setSearch: () => {},
});

Context.displayName = 'SearchContext';

export default Context;
