import React from 'react';
import {useLocation, useRouteMatch} from 'react-router-dom';
import {Box, Heading} from 'grommet';
import {useTranslation} from 'react-i18next';

import {Link} from 'common';
import {useAuthentication} from 'lib';
import {ScopeNameSpace} from 'authentication/types';
import ProductMenu from '../Products/ProductMenu';
import ReportMenu from '../Reports/ReportMenu';

const Navigation = () => {
  const {allowedNamespaces} = useAuthentication();
  const {search, pathname} = useLocation();
  const match = useRouteMatch<{id: string}>('/hotels');
  const {t} = useTranslation<ScopeNameSpace[]>(allowedNamespaces);

  const renderLinkContent = React.useCallback(
    (namespace: ScopeNameSpace): React.ReactElement => {
      return (
        <Heading
          color={pathname.split('/').includes(namespace) ? 'dark-1' : 'dark-4'}
          margin="none"
          level={3}
        >
          {t(`${namespace}:${namespace}`)}
        </Heading>
      );
    },
    [pathname, t]
  );

  const renderProductLink = React.useCallback((): React.ReactElement => {
    const {url} = match!;
    return (
      <ProductMenu
        url={url}
        key="products"
        renderLinkContent={renderLinkContent}
      />
    );
  }, [match, renderLinkContent]);

  const renderReportLink = React.useCallback((): React.ReactElement => {
    const {url} = match!;
    return (
      <ReportMenu
        url={url}
        key="reports"
        renderLinkContent={renderLinkContent}
      />
    );
  }, [match, renderLinkContent]);

  const renderLink = React.useCallback(
    (namespace: ScopeNameSpace): React.ReactElement => {
      const {url} = match!;
      return (
        <Link
          margin={{right: 'medium'}}
          to={{pathname: `${url}/${namespace}`, search}}
          key={namespace}
        >
          {renderLinkContent(namespace)}
        </Link>
      );
    },
    [match, renderLinkContent, search]
  );

  const renderLinks = React.useCallback((): React.ReactElement => {
    return (
      <>
        {allowedNamespaces.map((namespace) => {
          if (namespace === 'products') return renderProductLink();
          if (namespace === 'reports') return renderReportLink();

          return renderLink(namespace);
        })}
      </>
    );
  }, [allowedNamespaces, renderLink, renderProductLink, renderReportLink]);

  if (!match) {
    return null;
  }

  return (
    <Box direction="row" margin={{vertical: 'medium'}}>
      {renderLinks()}
    </Box>
  );
};

export default Navigation;
