import groupBy from 'lodash.groupby';

import {Hotel as HotelT} from 'hotels';
import {BaseApiBooking, BookingType} from 'bookings';

interface BaseItem<
  TApiBooking extends BaseApiBooking,
  TBooking extends BookingType<TApiBooking>
> {
  type: 'hotel' | 'booking';
  item: HotelT | TBooking;
}

export interface HotelItem<
  TApiBooking extends BaseApiBooking,
  TBooking extends BookingType<TApiBooking>
> extends BaseItem<TApiBooking, TBooking> {
  type: 'hotel';
  item: HotelT;
}

export interface BookingItem<
  TApiBooking extends BaseApiBooking,
  TBooking extends BookingType<TApiBooking>
> extends BaseItem<TApiBooking, TBooking> {
  type: 'booking';
  item: TBooking;
}

export type GroupedBookingItem<
  TApiBooking extends BaseApiBooking,
  TBooking extends BookingType<TApiBooking>
> = HotelItem<TApiBooking, TBooking> | BookingItem<TApiBooking, TBooking>;

export const buildHotelItem = <
  TApiBooking extends BaseApiBooking,
  TBooking extends BookingType<TApiBooking>
>(
  hotel: HotelT
): HotelItem<TApiBooking, TBooking> => ({
  type: 'hotel',
  item: hotel,
});

export const buildBookingItem = <
  TApiBooking extends BaseApiBooking,
  TBooking extends BookingType<TApiBooking>
>(
  booking: TBooking
): BookingItem<TApiBooking, TBooking> => ({
  type: 'booking',
  item: booking,
});

export const groupBookings = <
  TApiBooking extends BaseApiBooking,
  TBooking extends BookingType<TApiBooking>
>(
  bookings: TBooking[]
): Record<string, TBooking[]> => {
  return groupBy(bookings, (booking) => booking.accommodationId);
};

export const flatGroupedBookingItems = <
  TApiBooking extends BaseApiBooking,
  TBooking extends BookingType<TApiBooking>
>(
  bookings: TBooking[],
  findHotel: (accommodationId: number) => HotelT | undefined,
  sortBookings: (bookings: TBooking[]) => TBooking[]
): GroupedBookingItem<TApiBooking, TBooking>[] => {
  const items: GroupedBookingItem<TApiBooking, TBooking>[] = [];

  Object.entries(groupBookings(bookings)).forEach(
    ([accommodationId, bookings]) => {
      items.push(buildHotelItem(findHotel(parseInt(accommodationId, 10))!));
      sortBookings(bookings).forEach((booking) => {
        items.push(buildBookingItem(booking));
      });
    }
  );

  return items;
};
