import React, {useState, useRef, useCallback} from 'react';
import {format} from 'date-fns';
import {TextInput, Calendar, Drop} from 'grommet';

const DateInput = ({value, formatString, onSelect, size, ...props}) => {
  const container = useRef();

  const [calendarVisible, setCalendarVisible] = useState(false);
  const showCalendar = useCallback(
    () => setCalendarVisible(true),
    [setCalendarVisible]
  );
  const hideCalendar = useCallback(
    () => setCalendarVisible(false),
    [setCalendarVisible]
  );

  return (
    <>
      <TextInput
        {...props}
        value={format(value, "d'/'L")}
        ref={container}
        onClick={showCalendar}
      />
      {container.current && calendarVisible && (
        <Drop
          align={{top: 'bottom', right: 'right'}}
          elevation="small"
          target={container.current}
          onClickOutside={hideCalendar}
        >
          <Calendar
            range
            daysOfWeek
            size={size}
            onSelect={onSelect}
            firstDayOfWeek={1}
            locale="da-DK"
          />
        </Drop>
      )}
    </>
  );
};

DateInput.defaultProps = {
  formatString: "d'/'L",
};

export default DateInput;
