import React, {ReactElement} from 'react';
import {Box} from 'grommet';
import {Redirect, Route, RouteComponentProps, Switch} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';

import {useAuthentication} from 'lib';
import {ScopeNameSpace} from 'authentication/types';
import {Loading} from 'common';

import HotelsProvider from './Provider';
import {Provider as SearchProvider} from './search';
import HotelsContent from './HotelsContent';
import {Provider as BrandsProvider} from 'brands';
import Header from './Header';
import {PageProps} from './types';

const components: Record<
  ScopeNameSpace,
  React.ComponentType<PageProps> | React.FC<PageProps>
> = {
  bookings: React.lazy(() => import('./Bookings/Bookings')),
  bookings_guide: React.lazy(() => import('./Bookings/Guide')),
  rooming: React.lazy(() => import('./Rooming')),
  occupancy: React.lazy(() => import('./Occupancy')),
  vouchers: React.lazy(() => import('./Vouchers')),
  products: React.lazy(() => import('./Products')),
  reports: React.lazy(() => import('./Reports')),
};

const Hotels: React.FC<RouteComponentProps> = ({
  match: {path},
}): ReactElement => {
  const {loggedIn, allowedNamespaces, auth, ready} = useAuthentication();
  const {t} = useTranslation();

  const renderRoute = React.useCallback(
    (namespace: ScopeNameSpace): React.ReactElement => {
      const Component = components[namespace];
      const {scopes} = auth!;

      return (
        <Route
          path={`${path}/${namespace}`}
          key={namespace}
          render={(props) => (
            <Component {...props} scope={scopes.get(namespace)!} />
          )}
        />
      );
    },
    [auth, path]
  );

  const renderRoutes = React.useCallback(
    (): React.ReactElement => (
      <>{allowedNamespaces.map((namespace) => renderRoute(namespace))}</>
    ),
    [allowedNamespaces, renderRoute]
  );

  if (!ready) return <Loading />;
  if (!loggedIn) return <Redirect to="/login" />;

  return (
    <BrandsProvider>
      <HotelsProvider>
        <SearchProvider>
          <Helmet>
            <title>{t('hotels')}</title>
          </Helmet>
          <Box direction="row">
            <Box fill="horizontal">
              <Header />
              <HotelsContent>
                <Switch>{renderRoutes()}</Switch>
              </HotelsContent>
              {/* <Route path={`${path}/`} component={Hotel} /> */}
            </Box>
          </Box>
        </SearchProvider>
      </HotelsProvider>
    </BrandsProvider>
  );
};

export default Hotels;
