import React from 'react';

import Context from './Context';
import * as api from './api';
import * as actions from './actions';
import reducer, {initialState} from './reducer';
import debounce from 'lodash.debounce';
import {TransferDirection} from './types';

interface Props {
  date: string;
  direction: TransferDirection;
}

const Provider: React.FC<Props> = ({date, direction, children}) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const debouncedFetch = React.useRef(
    debounce<(date: string, direction: TransferDirection) => void>(
      (date, direction) => {
        api
          .fetchAll(date, direction)
          .then(({transfers}) => dispatch(actions.success(transfers)))
          .catch((message) => dispatch(actions.error(message)));
      }
    )
  );

  React.useEffect(() => {
    dispatch(actions.request());

    debouncedFetch.current(date, direction);
  }, [date, direction]);

  return <Context.Provider value={state}>{children}</Context.Provider>;
};

Provider.displayName = 'TransfersProvider';

export default Provider;
