import React from 'react';
import {Box} from 'grommet';
import {Helmet} from 'react-helmet';

import HotelsContext from '../Context';
import {Loading} from 'common';
import Navigation from './Navigation';
import {Provider as DateProvider} from '../date';
import Information from '../Information';

const HotelsContent: React.FC = ({children}) => {
  const {currentHotels, hotels, isLoading} = React.useContext(HotelsContext);

  return isLoading || !hotels || hotels.length === 0 ? (
    <Loading />
  ) : (
    <Box pad="medium">
      <Helmet>
        <title>{currentHotels.map((hotel) => hotel.name).join(' | ')}</title>
      </Helmet>

      {currentHotels.length > 0 ? (
        <>
          {currentHotels.length === 1 && <Information {...currentHotels[0]} />}
          {currentHotels.length > 1 && (
            <Information name="All" address={null} />
          )}
        </>
      ) : null}

      <Navigation />

      <DateProvider>
        <React.Suspense fallback={<Loading />}>{children}</React.Suspense>
      </DateProvider>
    </Box>
  );
};

export default HotelsContent;
