import jwtDecode from 'jwt-decode';
import groupBy from 'lodash.groupby';

import AuthenticationError from './AuthenticationError';
import Scope from './scope';
import {
  ApplicationType,
  AuthObject,
  Environment,
  ScopeNameSpace,
  ScopePermission,
} from './types';

interface DecodedToken {
  jti: string;
  trv_cst: number;
  iss: string;
  sub: string;
  scope: string;
  nbf: number;
  exp: number;
  env: Environment;
  aud: ApplicationType;
}

interface Validations {
  validateIssuer: boolean;
  validateNotBefore: boolean;
  validateExpiration: boolean;
}

type ValidationKey = keyof Validations;

const defaultValidations: Validations = {
  validateIssuer: false,
  validateNotBefore: false,
  validateExpiration: false,
};

const timestampToDate = (timestamp: number): Date => {
  return new Date(timestamp * 1000);
};

const validations: Record<
  ValidationKey,
  (decodedToken: DecodedToken) => boolean
> = {
  validateIssuer: (decodedToken: DecodedToken): boolean =>
    decodedToken.iss === process.env.REACT_APP_ISSUER,
  validateNotBefore: (decodedToken: DecodedToken): boolean =>
    timestampToDate(decodedToken.nbf) <= new Date(),
  validateExpiration: (decodedToken: DecodedToken): boolean =>
    timestampToDate(decodedToken.exp) >= new Date(),
};

export const validateParsedToken = (
  decodedToken: DecodedToken,
  options: Partial<Validations> = {}
): boolean => {
  const opts: Validations = {
    ...defaultValidations,
    ...options,
  };
  const validationKeys = (
    Object.entries(opts) as [ValidationKey, boolean][]
  ).reduce<ValidationKey[]>((acc, [validationKey, shouldValidate]) => {
    if (shouldValidate) {
      acc.push(validationKey);
    }

    return acc;
  }, []);

  if (validationKeys.length > 0) {
    return validationKeys.every((validationKey) =>
      validations[validationKey](decodedToken)
    );
  }

  return true;
};

export const decodeToken = (
  token: string,
  options: Partial<Validations> = {}
): DecodedToken => {
  const decodedToken = jwtDecode<DecodedToken>(token);

  if (!validateParsedToken(decodedToken, options))
    throw new AuthenticationError();

  return decodedToken;
};

const splitScopes = (
  scope: string
): {namespace: string; permission: string}[] => {
  return scope.split(' ').map((scp) => {
    const [namespace, permission] = scp.split(':');

    return {
      namespace,
      permission,
    };
  });
};

const parsePermissions = (permissions: string[]): ScopePermission[] =>
  [...new Set(permissions)] as ScopePermission[];

const parseScopes = (scope: string): Map<ScopeNameSpace, Scope> => {
  const scopeMap = new Map<ScopeNameSpace, Scope>();

  const groupedScopes = Object.entries(
    groupBy(splitScopes(scope), (scope) => scope.namespace)
  ) as [
    ScopeNameSpace,
    {namespace: string; objectType: string; permission: string}[]
  ][];

  groupedScopes.forEach(([namespace, scopes]) => {
    const scope = new Scope(
      namespace as ScopeNameSpace,
      parsePermissions(scopes.map((scope) => scope.permission))
    );

    scopeMap.set(scope.namespace, scope);
  });

  return scopeMap;
};

export const parseToken = (
  token: string,
  options: Partial<Validations> = {}
): AuthObject => {
  const decodedToken = decodeToken(token, options);

  return {
    jti: decodedToken.jti,
    travifyCustomer: decodedToken.trv_cst,
    issuer: decodedToken.iss,
    subject: decodedToken.sub,
    scopes: parseScopes(decodedToken.scope),
    notBefore: timestampToDate(decodedToken.nbf),
    expiration: timestampToDate(decodedToken.exp),
    token,
    environment: decodedToken.env,
    audience: decodedToken.aud,
  };
};
