import React, {ErrorInfo} from 'react';
import {Notifier} from '@airbrake/browser';
import airbrake from 'lib/airbrake';

interface State {
  hasError: boolean;
}

class ErrorBoundary<P extends object> extends React.Component<P, State> {
  private readonly airbrake: Notifier;

  constructor(props: P) {
    super(props);
    this.state = {hasError: false};
    this.airbrake = airbrake;
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    // Display fallback UI
    this.setState({hasError: true});
    // Send error to Airbrake
    this.airbrake.notify({
      error: error,
      params: {info: info},
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
