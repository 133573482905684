import React from 'react';
import {Heading, Text} from 'grommet';

import {Hotel} from 'hotels';

const Information: React.FC<Pick<Hotel, 'name' | 'address'>> = ({
  name,
  address,
}) => (
  <>
    <Heading level={1} color="dark-1" margin={{top: 'none', bottom: 'xsmall'}}>
      {name}
    </Heading>
    {address && (
      <Text color="dark-3">
        {address
          .split(/(\r)?\n/)
          .filter((x) => x !== null && x.trim() !== '')
          .join(', ')}
      </Text>
    )}
  </>
);

export default Information;
