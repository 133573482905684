import React, {PropsWithChildren, ReactElement} from 'react';
import {useLocation} from 'react-router-dom';
import {Box, BoxProps} from 'grommet';
import {Ascend, Descend} from 'grommet-icons';
import styled from 'styled-components';

import {Link, SortQueryParams} from 'common';
import {useQueryParams, SortOrder, appendSearch} from 'lib';

const HeaderLink = styled(Link)<{flexGrow?: boolean}>`
  border: 0;
  display: flex;
`;

const SortOrderIcon: React.FC<{order: SortOrder}> = ({order}) => {
  const Icon = {[SortOrder.Ascending]: Ascend, [SortOrder.Descending]: Descend}[
    order
  ];

  return (
    <Box margin={{left: 'xsmall'}}>
      <Icon size="16px" />
    </Box>
  );
};

interface Props<TKey extends string> extends BoxProps {
  sortKey: TKey;
}

function SortableHeader<TKey extends string>({
  sortKey,
  children,
  ...props
}: PropsWithChildren<Props<TKey>>): ReactElement {
  const {search} = useLocation();
  const {key, order} = useQueryParams<SortQueryParams<TKey>>();

  const createSortParams = React.useCallback(
    (newKey: TKey) => ({
      key: newKey,
      order:
        newKey !== key
          ? SortOrder.Ascending
          : order === SortOrder.Ascending
          ? SortOrder.Descending
          : SortOrder.Ascending,
    }),
    [key, order]
  );

  return (
    <Box direction="row" {...props}>
      <HeaderLink
        to={{search: appendSearch(search, createSortParams(sortKey))}}
      >
        {children}
        {key === sortKey && <SortOrderIcon order={order} />}
      </HeaderLink>
    </Box>
  );
}

export default SortableHeader;
