import React from 'react';
import {Box, Text, BoxProps} from 'grommet';
import {Icon} from 'grommet-icons';

interface Props {
  label: string;
  icon?: Icon;
}

const DataField: React.FC<Props & BoxProps> = ({
  label,
  icon: Icon,
  children,
  ...props
}) => (
  <Box {...props}>
    <Box direction="row" alignContent="center" margin={{bottom: 'xsmall'}}>
      {Icon && <Icon size="16px" />}
      <Text
        color="dark-3"
        weight="bold"
        size="small"
        margin={{left: Icon ? 'xsmall' : 'none'}}
      >
        {label.toUpperCase()}
      </Text>
    </Box>
    <Text color="dark-1">{children}</Text>
  </Box>
);

export default DataField;
