import React from 'react';
import * as yup from 'yup';

const useValidation = <T>(
  schema: yup.SchemaOf<T>,
  isInitialVaid: boolean = false
): [boolean, (value: T) => void] => {
  const [isValid, setIsValid] = React.useState(isInitialVaid);
  const handleChange = (value: T) => schema.isValid(value).then(setIsValid);

  return [isValid, handleChange];
};

export default useValidation;
