import React from 'react';
import {
  parseISO,
  isAfter,
  isBefore,
  addDays,
  differenceInDays,
  subDays,
  format,
  isValid,
  startOfToday,
} from 'date-fns';
import {
  Route,
  useLocation,
  useHistory,
  useRouteMatch,
  RouteComponentProps,
} from 'react-router-dom';

import {
  useQueryParams,
  closestDay,
  usePath,
  DateType,
  getDateType,
  appendSearch,
} from 'lib';
import {ExportMenu} from 'common';

import Context from './Context';
import {State} from './types';
import DateRangePage from './components/DateRangePage';
import DatePage from './components/DatePage';
import {Box} from 'grommet';
import SearchPage from 'hotels/SearchPage/SearchPage';

const defaultDate = startOfToday();
const defaultFrom = closestDay(startOfToday(), 6);
const defaultTo = addDays(defaultFrom, 6);

interface ComponentPageProps extends RouteComponentProps<{page: string}> {
  dateType: DateType;
  params: {
    from: Date;
    to: Date;
    date: Date;
  };
  handleNext: () => void;
  handlePrevious: () => void;
  handleFromSelect: (value: Date) => void;
  handleToSelect: (value: Date) => void;
  handleDateSelect: (value: Date) => void;
}

const ComponentPage: React.FC<ComponentPageProps> = ({
  match: {
    params: {page},
  },
  dateType,
  params,
  handleNext,
  handlePrevious,
  handleFromSelect,
  handleToSelect,
  handleDateSelect,
}) => {
  const renderPage = React.useCallback(() => {
    switch (dateType) {
      case 'period': {
        const {from, to} = params;
        return (
          <DateRangePage
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            handleFromSelect={handleFromSelect}
            handleToSelect={handleToSelect}
            from={from}
            to={to}
          />
        );
      }
      case 'date': {
        const {date} = params;
        return <DatePage handleDateSelect={handleDateSelect} date={date} />;
      }

      default:
        return null;
    }
  }, [
    dateType,
    handleDateSelect,
    handleFromSelect,
    handleNext,
    handlePrevious,
    handleToSelect,
    params,
  ]);

  return (
    <>
      <Box flex direction="row" align="start">
        <Box>{renderPage()}</Box>
        <Box flex justify="end" alignContent="end">
          <SearchPage />
        </Box>
      </Box>
      <ExportMenu page={page} />
    </>
  );
};

const Provider: React.FC = ({children}) => {
  const paths = usePath();
  const params = useQueryParams({
    from: parseISO,
    to: parseISO,
    date: parseISO,
  });
  const {pathname, search} = useLocation();
  const {replace, push} = useHistory();
  const match = useRouteMatch<{page: string}>(
    `/hotels/:page(${paths.join('|')})`
  );
  const dateType = React.useMemo<DateType>(() => {
    if (!match) return 'period';

    return getDateType(match.params.page);
  }, [match]);

  const getSearch = React.useCallback(
    (state: State): string => {
      switch (state.type) {
        case 'period': {
          const {from, to} = state;
          return appendSearch(search, {
            from: format(from, 'yyyy-MM-dd'),
            to: format(to, 'yyyy-MM-dd'),
          });
        }
        case 'date': {
          const {date} = state;
          return appendSearch(search, {date: format(date, 'yyyy-MM-dd')});
        }
        default:
          return '';
      }
    },
    [search]
  );

  React.useEffect(() => {
    if (dateType === 'date' && !isValid(params.date)) {
      replace({
        pathname,
        search: getSearch({
          type: dateType,
          date: defaultDate,
        }),
      });
    } else if (
      dateType === 'period' &&
      (!isValid(params.from) || !isValid(params.to))
    ) {
      replace({
        pathname,
        search: getSearch({
          type: dateType,
          from: defaultFrom,
          to: defaultTo,
        }),
      });
    }
  }, [replace, pathname, getSearch, dateType, params]);

  const handleDateSelect = React.useCallback(
    (value: Date) => {
      push({
        search: getSearch({
          type: 'date',
          date: value,
        }),
      });
    },
    [push, getSearch]
  );

  const handleFromSelect = React.useCallback(
    (value: Date) => {
      const {from, to} = params;
      push({
        search: getSearch({
          type: 'period',
          from: value,
          to: isAfter(value, to)
            ? addDays(value, Math.abs(differenceInDays(from, to)))
            : to,
        }),
      });
    },
    [params, push, getSearch]
  );

  const handleToSelect = React.useCallback(
    (value: Date) => {
      const {from, to} = params;
      push({
        search: getSearch({
          type: 'period',
          to: value,
          from: isBefore(value, from)
            ? subDays(value, Math.abs(differenceInDays(from, to)))
            : from,
        }),
      });
    },
    [params, push, getSearch]
  );

  const handlePrevious = React.useCallback(() => {
    const {from, to} = params;
    push({
      search: getSearch({
        type: 'period',
        to: subDays(to, 7),
        from: subDays(from, 7),
      }),
    });
  }, [params, push, getSearch]);

  const handleNext = React.useCallback(() => {
    const {from, to} = params;
    push({
      search: getSearch({
        type: 'period',
        to: addDays(to, 7),
        from: addDays(from, 7),
      }),
    });
  }, [params, push, getSearch]);

  if (dateType === 'date' && !isValid(params.date)) return null;
  if (dateType === 'period' && (!isValid(params.from) || !isValid(params.to)))
    return null;

  return (
    <>
      <Route
        exact
        path={`/hotels/:page(${paths.join('|')})`}
        render={(props) => (
          <ComponentPage
            {...props}
            dateType={dateType}
            params={params}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            handleFromSelect={handleFromSelect}
            handleToSelect={handleToSelect}
            handleDateSelect={handleDateSelect}
          />
        )}
      />
      <Context.Provider value={{type: dateType, ...params}}>
        {children}
      </Context.Provider>
    </>
  );
};

Provider.displayName = 'DateProvider';

export default Provider;
