import {useRef, useEffect} from 'react';

function usePrevious<T>(value: T, initialValue: T | undefined = undefined) {
  const ref = useRef<T | undefined>(initialValue);

  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default usePrevious;
