import React from 'react';
import {useTranslation} from 'react-i18next';
import {Box, Text} from 'grommet';

import {RangePicker} from 'common';

interface DatePageProps {
  handleDateSelect: (value: Date) => void;
  date: Date;
}

const DatePage: React.FC<DatePageProps> = ({date, handleDateSelect}) => {
  const {t} = useTranslation('common');

  return (
    <Box direction="row" justify="between" margin={{bottom: 'medium'}}>
      <Box direction="row">
        <Box margin={{right: 'medium'}}>
          <Text
            size="small"
            color="dark-3"
            weight="bold"
            margin={{bottom: 'xsmall'}}
          >
            {t('date').toUpperCase()}
          </Text>
          <RangePicker
            formatString="dd-MM-yyyy"
            value={date}
            onSelect={handleDateSelect}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DatePage;
